<template>
  <div v-if="canAccess('rp_produccion')" class="card">
    <BlockUI :blocked="loading" v-if="loading" :fullScreen="true" class="block-ui">
      <i v-if="loading" class="pi pi-spin pi-spinner block-ui-item"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <div class="col-12">

      <div class="grid" style="display: grid">
        <div class="p-col-12">
          <h6>Informe semanal de producción</h6>
        </div>
        <div class="grid" style="display: inline-flex">
          <div class="p-col-4" style="display: inline-flex">

             <span class="p-float-label" style="margin-right: 3px">
                                    <Calendar autocomplete="off"  :maxDate="new Date()" aria-autocomplete="false" id="start_date" :showIcon="true" v-model="date_start" dateFormat="yy-mm-dd"
                                              :yearNavigator="true" yearRange="2019:2040" />
                                    <label for="start_date">Fecha Inicio</label>
             </span>
            <span class="p-float-label" style="margin-left: 3px">
                                    <Calendar :maxDate="new Date()" :minDate="date_start" autocomplete="off" id="end_date" :showIcon="true" v-model="date_end" dateFormat="yy-mm-dd"
                                              :yearNavigator="true" yearRange="2019:2040" class="p-inputtext-sm"/>
                                    <label for="end_date">Fecha Fin</label>
             </span>
          </div>
          <div class="p-col-8" style="display: inline-flex">


            <Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2"
                    @click="getData()"/>
            <Button label="Exportar" icon="pi pi-file-excel" class="p-button-secondary p-mr-2"
                    @click="exportReport('XLS')"/>
            <Button label="Imprimir" icon="pi pi-file-pdf" class="p-button-info p-mr-2"
                    @click="exportReport('PDF')"/>

          </div>
        </div>


      </div>

    </div>
    <div v-for="(items,idx) in itemsTotal " :key="'tb'+idx">
      <DataTable :value="items.countries" style="margin-top: 20px">
        <ColumnGroup type="header">
          <Row>
            <Column header="País" :rowspan="4" :sortable="true" field="country"/>
          </Row>
          <Row>

            <Column header="ARRASTRE"  :colspan="2"  />
            <Column header="INGRESADAS" :colspan="2"/>
            <Column header="OK" :colspan="2"/>
            <Column header="PENDIENTES" :colspan="2"/>
            <Column header="REINGRESADAS" :colspan="2"/>
            <Column header="RECHAZADAS" :colspan="2"/>
            <Column header="DEVUELTAS" :colspan="2"/>
            <Column header="CONTADO" :colspan="2"/>
          </Row>
          <Row>
            <Column header="SEM" :sortable="true" field="mat_arrastre_week"/>
            <Column header="MES" :sortable="true" field="mat_arrastre_month"/>
            <Column header="SEM" :sortable="true" field="mat_inputs_week"/>
            <Column header="MES" :sortable="true" field="mat_inputs_month"/>
            <Column header="SEM" :sortable="true" field="mat_ok_week"/>
            <Column header="MES" :sortable="true" field="mat_ok_month"/>
            <Column header="SEM" :sortable="true" field="mat_pendent_week"/>
            <Column header="MES" :sortable="true" field="mat_pendent_month"/>
            <Column header="SEM" :sortable="true" field="mat_re_input_week"/>
            <Column header="MES" :sortable="true" field="mat_re_input_month"/>
            <Column header="SEM" :sortable="true" field="mat_rechazado_week"/>
            <Column header="MES" :sortable="true" field="mat_rechazado_month"/>
            <Column header="SEM" :sortable="true" field="mat_devuelta_week"/>
            <Column header="MES" :sortable="true" field="mat_devuelta_month"/>
            <Column header="SEM" :sortable="true" field="mat_cont_week"/>
            <Column header="MES" :sortable="true" field="mat_cont_month"/>


          </Row>
        </ColumnGroup>

        <Column field="country" header="País">
          <template #body="slotProps">
          <span class="image-text" style="color: #007be5;cursor: pointer"
                @click="viewDetail(slotProps.data)">{{ slotProps.data.country }}</span>
          </template>
        </Column>

        <Column header="SEM" :sortable="true" field="mat_arrastre_week" body-style="text-align:center"/>
        <Column header="MES" :sortable="true" field="mat_arrastre_month" body-style="text-align:center"/>
        <Column header="SEM" :sortable="true" field="mat_inputs_week"/>
        <Column header="MES" :sortable="true" field="mat_inputs_month"/>
        <Column header="SEM" :sortable="true" field="mat_ok_week"/>
        <Column header="MES" :sortable="true" field="mat_ok_month"/>
        <Column header="SEM" :sortable="true" field="mat_pendent_week"/>
        <Column header="MES" :sortable="true" field="mat_pendent_month"/>
        <Column header="SEM" :sortable="true" field="mat_re_input_week"/>
        <Column header="MES" :sortable="true" field="mat_re_input_month"/>
        <Column header="SEM" :sortable="true" field="mat_rechazado_week"/>
        <Column header="MES" :sortable="true" field="mat_rechazado_month"/>
        <Column header="SEM" :sortable="true" field="mat_devuelta_week"/>
        <Column header="MES" :sortable="true" field="mat_devuelta_month"/>
        <Column header="SEM" :sortable="true" field="mat_cont_week"/>
        <Column header="MES" :sortable="true" field="mat_cont_month"/>

        <ColumnGroup type="footer">
          <Row>
            <Column footer="Totals:" :colspan="1" footerStyle="text-align:right"/>
            <Column :footer="total.mat_arrastre_week"/>
            <Column :footer="total.mat_arrastre_month"/>
            <Column :footer="total.mat_inputs_week"/>
            <Column :footer="total.mat_inputs_month"/>
            <Column :footer="total.mat_ok_week"/>
            <Column :footer="total.mat_ok_month"/>
            <Column :footer="total.mat_pendent_week"/>
            <Column :footer="total.mat_pendent_month"/>
            <Column :footer="total.mat_re_input_week"/>
            <Column :footer="total.mat_re_input_month"/>
            <Column :footer="total.mat_rechazado_week"/>
            <Column :footer="total.mat_rechazado_month"/>
            <Column :footer="total.mat_devuelta_week"/>
            <Column :footer="total.mat_devuelta_month"/>
            <Column :footer="total.mat_cont_week"/>
            <Column :footer="total.mat_cont_month"/>


          </Row>
        </ColumnGroup>

      </DataTable>
    </div>

    <Dialog v-model:visible="dialogCountry" :style="{width: '80%'}" :header="country+': informe semanal de producción'"
            :modal="true" class="p-fluid">
      <div class="p-grid">
        <div class="p-col-12 p-md-12">
          <DataTable :value="itemsDetails" :paginator="true"
                     paginatorPosition="bottom" :rows="6">
            <ColumnGroup type="header">
              <Row>
                <Column :header="legendDate" :colspan="16"/>
              </Row>

              <Row>
                <Column header="Filiales" :rowspan="4" :sortable="true" field="country"/>
              </Row>
              <Row>
                <Column header="ARRASTRE" :colspan="2"/>
                <Column header="INGRESADAS" :colspan="2"/>
                <Column header="OK" :colspan="2"/>
                <Column header="PENDIENTES" :colspan="2"/>
                <Column header="REINGRESADAS" :colspan="2"/>
                <Column header="RECHAZADAS" :colspan="2"/>
                <Column header="DEVUELTAS" :colspan="2"/>
                <Column header="CONTADO" :colspan="2"/>
              </Row>
              <Row>
                <Column header="SEM" :sortable="true" field="mat_arrastre_week"/>
                <Column header="MES" :sortable="true" field="mat_arrastre_month"/>
                <Column header="SEM" :sortable="true" field="mat_inputs_week"/>
                <Column header="MES" :sortable="true" field="mat_inputs_month"/>
                <Column header="SEM" :sortable="true" field="mat_ok_week"/>
                <Column header="MES" :sortable="true" field="mat_ok_month"/>
                <Column header="SEM" :sortable="true" field="mat_pendent_week"/>
                <Column header="MES" :sortable="true" field="mat_pendent_month"/>
                <Column header="SEM" :sortable="true" field="mat_re_input_week"/>
                <Column header="MES" :sortable="true" field="mat_re_input_month"/>
                <Column header="SEM" :sortable="true" field="mat_rechazado_week"/>
                <Column header="MES" :sortable="true" field="mat_rechazado_month"/>
                <Column header="SEM" :sortable="true" field="mat_devuelta_week"/>
                <Column header="MES" :sortable="true" field="mat_devuelta_month"/>
                <Column header="SEM" :sortable="true" field="mat_cont_week"/>
                <Column header="MES" :sortable="true" field="mat_cont_month"/>


              </Row>
            </ColumnGroup>

            <Column field="name" header="Filiales">
              <template #body="slotProps">
                <span class="image-text">{{ slotProps.data.name }}</span>
              </template>
            </Column>

            <Column header="SEM" :sortable="true" field="mat_arrastre_week"/>
            <Column header="MES" :sortable="true" field="mat_arrastre_month"/>
            <Column header="SEM" :sortable="true" field="mat_inputs_week"/>
            <Column header="MES" :sortable="true" field="mat_inputs_month"/>
            <Column header="SEM" :sortable="true" field="mat_ok_week"/>
            <Column header="MES" :sortable="true" field="mat_ok_month"/>
            <Column header="SEM" :sortable="true" field="mat_pendent_week"/>
            <Column header="MES" :sortable="true" field="mat_pendent_month"/>
            <Column header="SEM" :sortable="true" field="mat_re_input_week"/>
            <Column header="MES" :sortable="true" field="mat_re_input_month"/>
            <Column header="SEM" :sortable="true" field="mat_rechazado_week"/>
            <Column header="MES" :sortable="true" field="mat_rechazado_month"/>
            <Column header="SEM" :sortable="true" field="mat_devuelta_week"/>
            <Column header="MES" :sortable="true" field="mat_devuelta_month"/>
            <Column header="SEM" :sortable="true" field="mat_cont_week"/>
            <Column header="MES" :sortable="true" field="mat_cont_month"/>

          </DataTable>
        </div>
      </div>

      <template #footer>
        <Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="dialogCountry = false"/>
      </template>
    </Dialog>

  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import service from '../service/report.service'
import {mapGetters} from "vuex";
import catalogueService from "../../core/service/catalog.service";
import bouncer from "../../../helpers/bouncer";
import FileSaver from 'file-saver';
import moment from "moment";

export default {
  mixins: [bouncer],
  name: "EnrollReport",
  data() {
    return {
      date_end:null,
      date_start:null,
      country: '',
      dialogCountry: false,
      service: service('enrollments'),
      items: [],
      itemsTotal: [],
      itemsDetails: [],
      total: [],
      loading: false,
      submitted: false,
      months_ini: [],
      weeks_fin: [],
      months_fin: [],
      data: {},
      month_ini: 0,
      week_ini: 0,
      month_fin: 0,
      week_fin: 0,
      legendDate:''
    }
  },
  mounted() {
    if (this.canAccess('rp_produccion')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Estadísticas', url: "javascript:void(0);"}]);
    }
  },
  methods: {
    viewDetail(row) {
      this.country = row.country;
      this.itemsDetails = row.details;
      this.legendDate='DEL: '+row.date_start+' AL:'+row.date_end;
      this.dialogCountry = true;
    },

    getData() {

      if (!moment(this.date_start).isValid() || !moment(this.date_end).isValid()) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Los campos de fecha son obligatorios para el reporte',
          life: this.$utils.toastLifeTime()
        });
      } else {
        if (moment(this.date_start) > moment(this.date_end)) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'El campo fecha hasta debe ser mayor o igual que el campo fecha desde',
            life: this.$utils.toastLifeTime()
          });
        } else {
          this.loading = true;
          this.service.getPositions({
            date_start:moment(this.date_start).format("YYYY-MM-DD"),
            date_end: moment(this.date_end).format("YYYY-MM-DD")
          }).then(x => {

            this.itemsTotal = x.data;
            this.total = x.data[0].totals;
          }).catch(err => {
            const message = err.response.data;
            let error = this.$utils.formatError(message);
            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
          }).finally(() => this.loading = false)
        }
      }
    }
    ,
    exportReport(command) {
      this.loading = true;
      this.service.getPositions({
        date_start:moment(this.date_start).format("YYYY-MM-DD"),
        date_end: moment(this.date_end).format("YYYY-MM-DD"),
        export: command
      })
          .then(x => {
            if(command=='PDF'){
               this.info = x.data;
                var byteCharacters = atob(x.data);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], {type: "application/pdf"});

                FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
            }else{
              window.open(x.data.url_file)
            }

          }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false)

    }
    ,
    getCatalog(event, route, field) {
      const originalRoute = route;
      catalogueService.getCatalog(event, route, field).then(x => {
        this[originalRoute] = x;
      }).catch(() => {
        this[originalRoute] = [];
      });
    }
    ,
  },
  computed: {
    ...mapGetters('bread', ['breadcrumb']),
  }
}
</script>

<style scoped>

span {
  width: 100%;
}

.p-dropdown .p-dropdown-trigger {
  height: 33px;
}

th {
  text-align: center !important;
  padding: 4px !important;
  font-weight: 600 !important;
  color: #495057 !important;
}
.block-ui {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000054;
  z-index: 99;
}

.block-ui-item {
  position: absolute;
  font-size: 3em;
  left: 50%;
  top: 50%;
}
</style>
